import { shuffleArray } from "../helpers";

export function initNews() {
    let uncovrNewsRessortsPromise;
    let uncovrNewsMode = localStorage.getItem("uncovrNewsMode") || "ressort";
    const uncovrNewsWrapper = $('.uncovr-news__wrapper');
    const uncovrNewsDetails = $('.uncovr-news-article[data-id]');

    function urlTitle(string) {
        return string
            .toLowerCase()
            .replaceAll("\u00F6", "oe")
            .replaceAll("\u00FC", "ue")
            .replaceAll("\u00E4", "ae")
            .replaceAll("\u00DF", "ss")
            .replace(/[^0-9a-z ]/g, '')
            .replaceAll(' ', '-')
    }

    function canonicalUrlForNews(news) {
        return `${window.location.hostname.startsWith("localhost") ? "" : "https://www.uncovr.com"}/News/Article/${ urlTitle(news.title) }-${ news.id }`;
    }

    async function loadNewsRessorts(force) {
        if (!uncovrNewsRessortsPromise || force) {
            uncovrNewsRessortsPromise = new Promise(async function (resolve) {
                const ressorts = {};
                const order = [];
                const loadedRessorts = await fetch("/news/article-api/resorts").then((d) => d.json());
                for (let ressort of loadedRessorts) {
                    ressorts[ressort.Id] = {
                        id: ressort.Id,
                        name: ressort.Name,
                        color: ressort.Color
                    }
                    order.push(ressort.Id);
                }
                resolve({
                    ressorts,
                    order
                });
            });
        }

        return uncovrNewsRessortsPromise;
    }

    async function parseApiNews(data) {
        return {
            id: data.Id,
            ressort: (await loadNewsRessorts()).ressorts[data.ResortId],
            title: data.Title,
            text: data.Text,
            imageId: data.ImageId,
            date: new Date(data.Date),
            previewDate: data.PreviewDate ? new Date(data.PreviewDate) : null,
            editor: data.Editor,
            source: data.Source,
            tags: data.Tags,
            video: data.Video,
            mediaSource: data.MediaSource
        }
    }

    /**
     * @param {{date: string, maxAgeHours: number, resortId: number, tags: string, topNews: boolean, skip: number, take: number }} options
     */
    async function loadNewsList(options) {
        const searchParams = new URLSearchParams(options);
        return await fetch(`/news/article-api/news?${searchParams.toString()}`).then((d) => d.json()).then((d) => Promise.all(d.map(parseApiNews)));
    }

    async function loadNews(id) {
        return await fetch(`/news/article-api/news/${id}`).then((d) => d.json()).then(parseApiNews);
    }

    const newsImageObserver = new IntersectionObserver((entries) => {
        if (entries.length) {
            for (let entry of entries) {
                if (entry.isIntersecting && !entry.target.src) {
                    entry.target.src = entry.target.getAttribute("data-src");
                }
            }
        }
    });

    function handleNewsImages(el) {
        $(el).find(".uncovr-news__item__image[data-src]").each((i, img) => {
            newsImageObserver.observe(img);
            img.addEventListener("load", () => {
                img.classList.add("loaded");
            });
        });
    }

    async function fillByDate() {
        $('.uncovr-news').remove();

        const baseDate = new Date();

        for (let d = 0; d < 7; d++) {
            const date = new Date(baseDate);
            date.setDate(date.getDate() - d);

            const newsList = await loadNewsList({ date: `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}` });
            const newsEls = [];

            if (uncovrNewsMode != "date") return;
            if (!newsList.length) continue;

            for (let news of newsList) {
                newsEls.push(`<article class="uncovr-news__item ${news.video ? "uncovr-news__item--video" : ""}" style="--ressort-color: ${news.ressort.color}">
                        <img class="uncovr-news__item__image" data-src="https://projekte.wunderwerk.at/uncovr-news/FE/Images/Get/${news.imageId}" alt="image accompanying press release">
                        <div class="uncovr-news__item__content">
                            <h3 class="uncovr-news__item__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</h3>
                        </div>
                        <a class="uncovr-news__item__link-overlay" href="${canonicalUrlForNews(news)}"></a>
                    </article>`);
            }

            const newsSectionEl = $(`<section class="uncovr-news uncovr-news--by-date"></section>`);

            const dateEl = $(`<div class="uncovr-news__date__wrapper">
                        <h2 class="uncovr-news__date"><strong>${['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'][date.getDay()]}</strong>${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1).toString().padStart(2, "0")}.</h2>
                    </div>`);
            newsSectionEl.append(dateEl);

            newsSectionEl.append(newsEls.join(''));

            uncovrNewsWrapper.append(newsSectionEl);

            handleNewsImages(newsSectionEl);
        }
    }

    async function fillByPreview() {
        $('.uncovr-news').remove();

        const newsList = (await loadNewsList({ take: 100, maxAgeHours: 168 }))
            .filter((news) => !!news.previewDate)
            .sort((a, b) => a.previewDate - b.previewDate);
        const newsEls = [];

        if (uncovrNewsMode != "preview") return;
        if (!newsList.length) return;

        const makeGroup = () => {
            const newsSectionEl = $(`<section class="uncovr-news uncovr-news--by-date"></section>`);

            const dp = previousDay.split('.')
            const dateEl = $(`<div class="uncovr-news__date__wrapper">
                                <h2 class="uncovr-news__date"><strong>${['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'][dp[0]]}</strong>${dp[1].padStart(2, "0")}.${(parseInt(dp[2]) + 1).toString().padStart(2, "0")}.</h2>
                            </div>`);
            newsSectionEl.append(dateEl);

            newsSectionEl.append(newsEls.join(''));

            uncovrNewsWrapper.append(newsSectionEl);

            newsEls.length = 0;

            handleNewsImages(newsSectionEl);
        }

        let previousDay = null;
        for (let news of newsList) {
            const newsItem = `<article class="uncovr-news__item ${news.video ? "uncovr-news__item--video" : ""}" style="--ressort-color: ${news.ressort.color}">
                    <img class="uncovr-news__item__image" data-src="https://projekte.wunderwerk.at/uncovr-news/FE/Images/Get/${news.imageId}" alt="image accompanying press release">
                    <div class="uncovr-news__item__content">
                        <h3 class="uncovr-news__item__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</h3>
                    </div>
                    <a class="uncovr-news__item__link-overlay" href="${canonicalUrlForNews(news)}"></a>
                </article>`;

            let currentDay = `${news.previewDate.getDay()}.${news.previewDate.getDate()}.${news.previewDate.getMonth()}.${news.previewDate.getFullYear()}`;
            if (previousDay != currentDay) {
                if (previousDay) {
                    makeGroup();
                }
                previousDay = currentDay;
            }
            newsEls.push(newsItem);
            if (news.id == newsList[newsList.length - 1].id) {
                makeGroup();
            }
        }
    }

    async function fillByRessort() {
        const { ressorts, order } = await loadNewsRessorts();

        $('.uncovr-news').remove();

        for (let ressortId of order) {
            const ressort = ressorts[ressortId];
            const newsEls = [];

            const newsList = await loadNewsList({ resortId: ressortId, take: 12 });

            if (uncovrNewsMode != "ressort") return;

            let nc = 0;
            for (let news of newsList) {
                newsEls.push(`<article class="uncovr-news__item ${news.video ? "uncovr-news__item--video" : ""}">
                            <img class="uncovr-news__item__image" data-src="https://projekte.wunderwerk.at/uncovr-news/FE/Images/Get/${news.imageId}" alt="image accompanying press release">
                            <div class="uncovr-news__item__content">
                                <h3 class="uncovr-news__item__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</h3>
                            </div>
                            <a class="uncovr-news__item__link-overlay" href="${canonicalUrlForNews(news)}"></a>
                        </article>`);
            }

            const newsSectionEl = $(`<section class="uncovr-news" style="--ressort-color:${ressort.color}"></section>`);
            const ressortEl = $(`<div class="uncovr-news__ressort__wrapper">
                        <h2 class="uncovr-news__ressort">${ressort.name}</h2>
                    </div>`);

            newsSectionEl.append(ressortEl);

            newsSectionEl.append(newsEls.join(''));

            uncovrNewsWrapper.append(newsSectionEl);

            handleNewsImages(newsSectionEl);
        }
    }

    function fillNews(mode) {
        if (mode == "date") {
            fillByDate();
        } else if (mode == "preview") {
            fillByPreview();
        } else {
            fillByRessort();
        }
    }

    if (uncovrNewsWrapper.length) {

        const toggles = document.createElement("div");
        toggles.classList.add("uncovr-news__filter")

        toggles.innerHTML = `
                <button class="uncovr-news__filter__button ${uncovrNewsMode == "ressort" ? "uncovr-news__filter__button--active" : ""}" data-sort-by="ressort">Thematisch</button>
                <button class="uncovr-news__filter__button ${uncovrNewsMode == "date" ? "uncovr-news__filter__button--active" : ""}" data-sort-by="date">Chronologisch</button>
                <button class="uncovr-news__filter__button ${uncovrNewsMode == "preview" ? "uncovr-news__filter__button--active" : ""}" data-sort-by="preview">Vorschau</button>
            `;

        uncovrNewsWrapper.append(toggles);

        for (let toggle of toggles.querySelectorAll('.uncovr-news__filter__button')) {
            toggle.addEventListener('click', (ev) => {
                const mode = ev.currentTarget.getAttribute("data-sort-by");
                if (mode != uncovrNewsMode) {
                    uncovrNewsMode = mode;
                    localStorage.setItem("uncovrNewsMode", mode);
                    fillNews(mode);
                    ev.currentTarget.parentElement.querySelector('.uncovr-news__filter__button--active')?.classList.remove('uncovr-news__filter__button--active')
                    ev.currentTarget.classList.add('uncovr-news__filter__button--active');
                }
            });
        }

        fillNews(uncovrNewsMode);
    }

    if (uncovrNewsDetails.length) {
        uncovrNewsDetails.each(async (idx, el) => {
            async function showNews(id) {
                const json = el.getAttribute("data-json");
                const news = await (json ? parseApiNews(JSON.parse(json)) : loadNews(id));

                console.log(news, json);

                el.style.setProperty("--ressort-color", news.ressort.color);

                $('.uncovr-news-article__video, .uncovr-news-article__info, .uncovr-news-article__content, .uncovr-news-article__image', el).remove();

                el.innerHTML += `
                    ${news.video
                        ? `<div class="uncovr-news-article__video" style="--image-src: url(${`https://projekte.wunderwerk.at/uncovr-news/FE/Images/Get/${news.imageId}`})" data-video-id="${news.video}">
                            <div class="uncovr-news-article__video__background"></div>
                            <div id="youtube-player-1" class="uncovr-news-article__video__placeholder"></div>
                        </div>`
                        : `<img class="uncovr-news-article__image" src="https://projekte.wunderwerk.at/uncovr-news/FE/Images/Get/${news.imageId}" alt="image accompanying press release">`}
                    <div class="uncovr-news-article__content">
                        <h1 class="uncovr-news-article__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</h1>
                        <div class="uncovr-news-article__text">${news.text.replace(/(https?:\/\/([^\s]+?)\/?(\s|$))/g, '<a href="$1" target="_blank">$2</a>$3')}</div>
                    </div>
                    <div class="uncovr-news-article__info">
                        <div class="uncovr-news-article__ressort-wrapper">
                            <h2 class="uncovr-news-article__ressort">${news.ressort.name}</h2>
                        </div>
                        <div class="uncovr-news-article__info__content">
                            <div class="uncovr-news-article__info__date">${news.date.getDate().toString().padStart(2, "0")}.${(news.date.getMonth() + 1).toString().padStart(2, "0")}.${news.date.getFullYear()}</div>
                            <hr />
                            ${news.source ? `<div class="uncovr-news-article__info__source"><strong>Originalquelle</strong> <a href="${news.source}" target="_blank"><span class="icon icon-export"></span> ${news.source.replace(/https?:\/\//, '').split('/')[0]}</a></div>` : ''}
                            ${news.mediaSource
                        ? news.mediaSource.startsWith("http")
                            ? `<div class="uncovr-news-article__info__media-source"><strong>Bildquelle</strong> <a href="${news.mediaSource}" target="_blank"><span class="icon icon-export"></span> ${news.mediaSource.replace(/https?:\/\//, '').split('/')[0]}</a></div>`
                            : `<div class="uncovr-news-article__info__media-source"><strong>Bildrechte</strong> ${news.mediaSource}</div>`
                        : ''
                    }
                            <button class="black-bordered small" style="display: flex; gap: .5rem; font-weight: 300; font-size: .875rem" data-success="Kopiert!" data-error="Fehler!">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="width: 1rem;">
                                    <path d="M272 416C263.2 416 256 423.2 256 432V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V192c0-17.67 14.33-32 32-32h112C184.8 160 192 152.8 192 144C192 135.2 184.8 128 176 128H63.99c-35.35 0-64 28.65-64 64l.0098 256C0 483.3 28.65 512 64 512h160c35.35 0 64-28.65 64-64v-16C288 423.2 280.8 416 272 416zM502.6 86.63l-77.25-77.25C419.4 3.371 411.2 0 402.7 0H288C252.7 0 224 28.65 224 64v256c0 35.35 28.65 64 64 64h160c35.35 0 64-28.65 64-64V109.3C512 100.8 508.6 92.63 502.6 86.63zM416 45.25L466.7 96H416V45.25zM480 320c0 17.67-14.33 32-32 32h-160c-17.67 0-32-14.33-32-32V64c0-17.67 14.33-32 32-32h96l.0026 64c0 17.67 14.33 32 32 32H480V320z"/>
                                </svg>
                                Meldungstext kopieren
                            </button>

                            <div class="uncovr-news-article__info__share">
                                <strong>Teilen</strong>
                                <div class="uncovr-news-article__info__share__buttons">
                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(canonicalUrlForNews(news))}" target="_blank"><img src="/Content/images/logo_linkedin.svg" width="32" alt="linkedin logo"></a>
                                    <a href="http://www.twitter.com/share?text=${encodeURIComponent(news.title)}&url=${encodeURIComponent(canonicalUrlForNews(news))}" target="_blank"><img src="/Content/images/logo_twitter.svg" width="24" alt="X (Twitter) logo"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <aside class="uncovr-news-article__more">
                        <h1 class="uncovr-news-article__more__title">Mehr aus <strong>${news.ressort.name}</strong></h1>
                        <div class="uncovr-news-article__more__items uncovr-news-article__more__items--vertical"></div>
                    </aside>
                    <aside class="uncovr-news-article__footer">
                        <h1 class="uncovr-news-article__more__title">Mehr aus <strong>${news.ressort.name}</strong></h1>
                        <div class="uncovr-news-article__more__items uncovr-news-article__more__items--horizontal"></div>
                    </aside>
                `;

                $("button", el).off().on('click', async function (ev) {
                    ev.currentTarget.classList.remove("success", "error");
                    try {
                        await navigator.clipboard.writeText(`${news.title}\n\n${news.text}`);
                        ev.currentTarget.offsetHeight;
                        $(ev.currentTarget).addClass("success");
                    } catch (error) {
                        console.error(error.message);
                        $(ev.currentTarget).addClass("error");
                    }
                });

                setupVideo(el);

                return news;
            }

            const newsId = el.getAttribute("data-id").split('-').pop();
            const news = await showNews(newsId);


            let currentNewsIndex = -1;
            let newsList;
            function showNextNews(dir) {
                if (newsList && currentNewsIndex > -1) {
                    currentNewsIndex = currentNewsIndex + dir;
                    if (currentNewsIndex < 0) currentNewsIndex = newsList.length - 1;
                    else if (currentNewsIndex >= newsList.length - 1) currentNewsIndex = 0;
                    const news = newsList[currentNewsIndex];
                    showNews(news.id);
                    window.history.pushState({}, "", canonicalUrlForNews(news));
                }
            }

            const moreAside = $('.uncovr-news-article__more .uncovr-news-article__more__items');
            const moreFooter = $('.uncovr-news-article__footer .uncovr-news-article__more__items');
            //fetch more
            loadNewsList({ resortId: news.ressort.id, take: 13 }).then(async function (d) {
                newsList = [...d];
                d = shuffleArray(d);
                const currentNews = news;
                let idx = -1;
                for (let news of d) {
                    idx++;

                    if (currentNews.id == news.id) {
                        currentNewsIndex = idx;
                        continue;
                    }

                    moreAside[0].innerHTML += `
                        <a class="uncovr-news-article__more__item" href="${canonicalUrlForNews(news)}">
                            <div class="uncovr-news-article__more__item__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</div>
                        </a>
                    `;

                    moreFooter[0].innerHTML += `
                        <a class="uncovr-news-article__more__item" href="${canonicalUrlForNews(news)}">
                            <img class="uncovr-news-article__more__item__image" src="https://projekte.wunderwerk.at/uncovr-news/FE/Images/Get/${news.imageId}" alt="image accompanying press release">
                            <div class="uncovr-news-article__more__item__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</div>
                        </a>
                    `;
                }
            });

            let maybeSwiping = null;
            el.addEventListener('touchstart', (e) => {
                if (e.touches.length === 1) {
                    maybeSwiping = {
                        time: Date.now(),
                        x: e.touches[0].clientX,
                        y: e.touches[0].clientY,
                        lastTime: Date.now(),
                        lastX: e.touches[0].clientX,
                        lastY: e.touches[0].clientY,
                        dx: 0,
                        dy: 0,
                        vx: 0,
                        vy: 0,
                    }
                } else {
                    maybeSwiping = null;
                }
            })
            el.addEventListener('touchmove', (e) => {
                if (maybeSwiping) {
                    const dx = e.touches[0].clientX - maybeSwiping.lastX;
                    const dy = e.touches[0].clientY - maybeSwiping.lastY;
                    if (2 * Math.abs(dx) > Math.abs(dy)) {
                        const dt = Math.max(1, Date.now() - maybeSwiping.lastTime);
                        maybeSwiping.dx = dx;
                        maybeSwiping.dy = dy;
                        maybeSwiping.vx = (maybeSwiping.vx + dx / dt) / 2;
                        maybeSwiping.vy = (maybeSwiping.vy + dy / dt) / 2;
                        maybeSwiping.lastTime = Date.now();
                        maybeSwiping.lastX = e.touches[0].clientX;
                        maybeSwiping.lastY = e.touches[0].clientY;
                        e.preventDefault();
                    } else {
                        maybeSwiping = null
                    }
                }
            })
            el.addEventListener('touchend', (e) => {
                if (maybeSwiping) {
                    const dx = maybeSwiping.x - maybeSwiping.lastX;
                    const dy = maybeSwiping.y - maybeSwiping.lastY;
                    if (
                        Date.now() - maybeSwiping.time > 100 &&
                        Math.abs(dx) > 3 * Math.abs(dy) &&
                        Math.abs(maybeSwiping.vx) > .1
                    ) {
                        showNextNews(Math.sign(maybeSwiping.vx) * -1)
                    }
                }
            })
            el.addEventListener('touchcancel', (e) => {
                maybeSwiping = null;
            })
        });


        //video handling

        var ytApiPromise = null;
        function loadYTApi() {
            if (!ytApiPromise) {
                var tag = document.createElement('script');
                tag.src = "https://www.youtube.com/player_api";
                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                ytApiPromise = new Promise((resolve) => {
                    window.onYouTubePlayerAPIReady = () => {
                        resolve();
                    }
                })
            }

            return ytApiPromise;
        }

        function setupVideo(el) {
            var videoWrappers = document.querySelectorAll('.uncovr-news-article__video');

            for (let videoWrapper of videoWrappers) {
                videoWrapper.addEventListener('click', (e) => {
                    const id = e.currentTarget.getAttribute('data-video-id') || 'vo4rbhR_vsk';
                    videoWrapper.classList.add('uncovr-news-article__video--loading');
                    const paddingTop = parseInt(getComputedStyle(videoWrapper).paddingTop);
                    console.log(videoWrapper.offsetHeight);
                    loadYTApi().then(() => {
                        var player = new YT.Player(videoWrapper.querySelector('.uncovr-news-article__video__placeholder').id, {
                            height: videoWrapper.offsetHeight - paddingTop,
                            width: videoWrapper.offsetWidth,
                            videoId: id,
                            playerVars: {
                                autoplay: 1,
                                showinfo: 0,
                                autohide: 1,
                                modestbranding: 1
                            },
                        });
                        player.addEventListener('onReady', () => {
                            videoWrapper.classList.remove('uncovr-news-article__video--loading');
                            videoWrapper.classList.add('uncovr-news-article__video--playing');
                            player.playVideo();
                        })
                    })
                })
            }
        }
    }
}